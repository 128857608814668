import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Dynamic from "../../Components/DynamicBanner/Dynamic";
import aboutBG from "../../assets/About.jpg";
import GLightbox from "glightbox";
import servicesData from "./ServiceData";
import "./Services.css";

const SingleServiceView = () => {
  const { slug } = useParams(); // ✅ URL se slug fetch karna
  console.log(slug, "🔹 Slug from URL");

  const service = servicesData.find(
    (s) => s.slug?.toLowerCase() === slug?.toLowerCase()
  );

  useEffect(() => {
    GLightbox({ selector: ".glightbox" });
  }, []);

  if (!service) return <p>Service not found!</p>; // ✅ Agar service na mile toh error show kare

  return (
    <>
      {/* 🔹 Dynamic Banner */}
      <Dynamic
        backgroundImage={aboutBG}
        heading={service.title}
        subheading={service.description}
      />

      {/* 🔹 Service Details Section */}
      <section className="services__details--section section--padding pt-5 mb-30">
        <div className="container">
          <div className="row" data-aos="fade-up" data-aos-duration={1200}>
            <div className="col-md-12">
              <h2 className="text-black">{service.title}</h2>

              <img
                src={service.image}
                alt={service.title}
                className="img-fluid w-100 rounded-5 pt-2"
              />
              <div
                dangerouslySetInnerHTML={{ __html: service.content }}
                className="service-content mb-5 pt-4"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleServiceView;
