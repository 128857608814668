import React from "react";
import { Link } from "react-router-dom";
import "../Footer/Footer.css";

const FooterCopyright = () => {
  return (
    <div className="row ">
      <div className="col-md-12 border-top border-white  py-3 text-center">
        <p className="pb-4 text-white fs-6 pt-3">
          © Copyright 2025 WPM All Rights Reserved. &nbsp;|&nbsp; Powered by{" "}
          <Link
            to="https://www.adsrole.com"
            className="text-danger fs-6"
            target="_blank"
          >
            AdsRole
          </Link>
        </p>
      </div>
    </div>
  );
};

export default FooterCopyright;
