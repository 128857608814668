import React, { useState } from "react";

import "./NotFound.css";
import { Link } from "react-router-dom";

const NotFOund = () => {
  return (
    <section
      className="page_404 bg-white font-serif p-10 d-flex align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="container text-center">
        <div className="contant_box_404">
          <h1 className="text-6xl">404</h1>
          <h3 className="text-4xl">Looks like you're lost</h3>
          <p className="text-lg">
            The page you are looking for is not available!
          </p>
          <Link to="/" className="link_404 ">
            Go to Home
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFOund;
