import React from "react";

const SellProperty = () => {
  return (
    <div>
      <h1>COMING SOON ...</h1>
    </div>
  );
};

export default SellProperty;
