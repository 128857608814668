import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./layouts/Navbar/Navbar";
import Header from "./layouts/Header/Header";
import Footer from "./layouts/Footer/Footer";
import HeroSection from "./pages/HeroSection/home";
import ContactUs from "./pages/ContactSection/ContactUs";
import AboutView from "./pages/AboutSection/AboutView";
import AdvisoryManagement from "./pages/AdvisoryManagement/AdvisoryManagement";
import Blog from "./pages/BlogSection/Blog";
import BlogDetail from "./pages/BlogSection/BlogDetail";
import ServiceOverview from "./pages/ServiceOverview/ServiceOverview";
import SingleServiceView from "./pages/ServiceOverview/SingleServiceView";
import Properties from "./pages/PropertiesPage/Properties";
import SingleProperty from "./pages/PropertiesPage/SingleProperty";
import TechnicalService from "./pages/TechnicalService/TechnicalService";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import SellProperty from "./pages/PropertiesPage/SellProperty";
import AOS from "aos";
import NotFound from "./Components/NotFound";
import TawkTo from "./pages/TawkTo";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <div className="App">
      <Navbar />
      <Header />
      <TawkTo />
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about" element={<AboutView />} />
        <Route path="/advisory-management" element={<AdvisoryManagement />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/BlogDetail/:blogId" element={<BlogDetail />} />
        <Route path="/service" element={<ServiceOverview />} />
        <Route path="/services/:slug" element={<SingleServiceView />} />

        <Route path="/technical-service" element={<TechnicalService />} />
        {/* <Route path="/Properties" element={<Properties />} />
        <Route
          path="/PropertiesDetail/:propertyId"
          element={<SingleProperty />}
        /> */}
        <Route path="/SellProperty" element={<SellProperty />} />
        <Route path="terms-condition" element={<TermsConditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
