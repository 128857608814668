import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Scrollbar, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import videogif from "../../../videos/videogif.gif";
import { FaStar } from "react-icons/fa";
const ClientCard = ({ card }) => (
  <div className=" p-4 pt-5 d-flex  ">
    <div
      className="card text-center bg-white shadow rounded-5 mt-3"
      style={{ height: "269px", cursor: "pointer" }}
      // onClick={() =>
      //   window.open(
      //     "https://www.google.com/search?hl=en-IN&gl=in&q=1401,+4th+Floor,+Wealth+Clinic+Pvt.+Ltd.,+Tower-1,+Express+Trade+Tower,+2,+B-36,+Block+B,+Sector+132,+Noida,+Uttar+Pradesh+201301&ludocid=14966508337579957575&lsig=AB86z5Vbd4rKLr2uMr0R2kD4iO#lrd=0x390ce70438c07157:0xcfb3b8097d601d47,3",
      //     "_blank"
      //   )
      // }
    >
      <div className="card-body position-relative ">
        <div className="mb-4">
          <img
            src={card.image}
            alt="client"
            className="rounded-circle border border-secondary"
            style={{
              width: "80px",
              height: "80px",
              position: "absolute",
              top: "-40px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        </div>
        <p className="card-text text-secondary mt-5 fs-6 fs-md-5">
          {card.review}
        </p>
        <h5 className="card-title font-weight-bold fs-3">{card.name}</h5>
        <div className="rating" style={{ color: "#f39c12", marginTop: "10px" }}>
          {Array.from({ length: 5 }, (_, i) => (
            <FaStar key={i} color={i < card.rating ? "#f39c12" : "#ccc"} />
          ))}
          {/* {"★".repeat(card.rating)}
          {(<CiStar />).repeat(5 - card.rating)} */}
          {/* <CiStar style={{ color: "black" }} /> */}
        </div>
      </div>
    </div>
  </div>
);

const ClientsReviewComponent = () => {
  const clientCards = [
    {
      name: "Parul Singh",
      review:
        "The team culture is fantastic. Everyone is supportive and willing to help, which fosters a sense of community.",

      image:
        "https://1.bp.blogspot.com/-AO5j2Y9lzME/YLjr3mxiqAI/AAAAAAAACPE/KAaYYTtQTrgBE3diTbxGoc4U4fCGx-C2gCNcBGAsYHQ/s16000/team-1-4.jpg",
      rating: 3,
    },
    {
      name: "Sandeep Srivastava",
      review:
        "Great team of real estate professionals who assist you at every stage and provide really valuable information and great deals on property investments.",
      image:
        "https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg",
      rating: 5,
    },
    {
      name: "Meera Sharma",
      review:
        "Highly Recommended Real Estate Services! Had an amazing experience with Weblight Property Management. .",
      image:
        "https://1.bp.blogspot.com/-AO5j2Y9lzME/YLjr3mxiqAI/AAAAAAAACPE/KAaYYTtQTrgBE3diTbxGoc4U4fCGx-C2gCNcBGAsYHQ/s16000/team-1-4.jpg",
      rating: 5,
      link: "https://example.com/sumit",
    },
    {
      name: "Ananya Verma",
      review:
        "Great team of real estate professionals who assist you at every stage and provide really valuable information and great deals on property investments.",
      image:
        "https://1.bp.blogspot.com/-vhmWFWO2r8U/YLjr2A57toI/AAAAAAAACO4/0GBonlEZPmAiQW4uvkCTm5LvlJVd_-l_wCNcBGAsYHQ/s16000/team-1-2.jpg",
      rating: 5,
    },
  ];

  const swiperRef = useRef(null);

  const handleScale = (swiper) => {
    const slides = document.querySelectorAll(".swiper-slide");
    slides.forEach((slide, index) => {
      slide.style.transform =
        index === swiper.activeIndex ? "scale(1.1)" : "scale(1)";
      slide.style.transition = "transform 0.3s ease-in-out";
    });
  };

  useEffect(() => {
    if (swiperRef.current) {
      handleScale(swiperRef.current);
    }
  }, []);

  return (
    <div className="container text-center mt-5 pb-0 pb-lg-5 mb-4">
      <div className="d-flex align-items-center justify-content-center">
        <h2 className="fw-bold text-center mb-0">What Our Customers Say</h2>
        {/* <img
          src={videogif}
          alt="Video Testimonial"
          style={{ width: "40px", height: "170px", marginLeft: "20px" }}
        /> */}
      </div>

      <div className="mt-4">
        <Swiper
          breakpoints={{
            640: {
              slidesPerView: 1,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 2,
              centeredSlides: true,
            },
            1024: {
              slidesPerView: 3,
              centeredSlides: true,
            },
          }}
          loop={true}
          speed={2000}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          scrollbar={{ draggable: false }}
          modules={[Autoplay, Zoom, Scrollbar, Pagination]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            handleScale(swiper);
          }}
          onSlideChange={(swiper) => handleScale(swiper)}
        >
          {clientCards.map((card, i) => (
            <SwiperSlide key={i}>
              <ClientCard card={card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="mt-4">
        <button
          onClick={() => {
            swiperRef.current?.slidePrev();
            handleScale(swiperRef.current);
          }}
          className="btn border border-2 border-black rounded-circle me-2 bg-none"
        >
          &lt;
        </button>
        <button
          onClick={() => {
            swiperRef.current?.slideNext();
            handleScale(swiperRef.current);
          }}
          className="btn border border-2 border-black rounded-circle bg-none"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ClientsReviewComponent;
