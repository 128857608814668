import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div
      style={{ backgroundColor: "rgb(177 177 179 / 96%)" }}
      className=" d-none d-md-block"
    >
      <div className="container-fluid ">
        <div className="row  p-2 p-lg-3 ">
          <div className="col-md-10 ">
            <div className="ltn__top-bar-menu">
              <ul className="list-unstyled mb-0">
                <li className="text-black">
                  <Link to="tel:+919311254752" className="text-black">
                    <i className="bi bi-telephone icon-white" /> +91 9311254752
                  </Link>
                </li>
                <li className="text-black">
                  <Link to="mailto:info@wpmindia.com" className="text-black">
                    <i className="bi bi-envelope icon-white" />{" "}
                    info@wpmindia.com
                  </Link>
                </li>
                <li className="text-black">
                  <Link to="/locations" className="text-black">
                    <i className="bi bi-geo-alt icon-white" /> C-70A, 7th Floor,
                    Supermart-1, DLF phase 4, Gurgaon
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 d-flex justify-content-end">
            <div className="top-bar-right text-center">
              <div className="ltn__top-bar-menu">
                <ul className="list-unstyled mb-0">
                  <li>
                    <div className="ltn__social-media">
                      <ul className="d-flex justify-content-center list-unstyled mb-0">
                        <li className="mx-2">
                          <Link to="https://www.facebook.com" title="Facebook">
                            <i className="bi bi-facebook text-danger" />
                          </Link>
                        </li>
                        <li className="mx-2">
                          <Link to="https://www.twitter.com" title="Twitter">
                            <i className="bi bi-linkedin text-danger" />
                          </Link>
                        </li>
                        <li className="mx-2">
                          <Link
                            to="https://www.instagram.com"
                            title="Instagram"
                          >
                            <i className="bi bi-instagram text-danger" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
