import { Link } from "react-router-dom";
import blog_1 from "../../../assets/blog/blog1.png";
import blog_2 from "../../../assets/blog/blog2.png";
import blog_3 from "../../../assets/blog/blog3.png";
import blog_4 from "../../../assets/blog/blog-vertical1.png";
import "./AdvantageSection.css";

const blogData = [
  {
    id: 1,
    imgSrc: blog_1,
    badge: "Real Estate",
    date: "02 Apr 2024",
    title: "Top 8 Real Estate Trends",
    description:
      "Explore 2024’s key real estate trends, from tech innovations to shifts in buyer preferences and sustainable practices buyer",
    link: "blog-details.html",
  },
  {
    id: 2,
    imgSrc: blog_2,
    badge: "Investment",
    date: "15 Mar 2024",
    title: "Maximizing Real Estate Investment",
    description:
      "Discover strategies to boost real estate returns, including location selection, upgrades, and risk management.",
    link: "blog-details.html",
  },
  {
    id: 3,
    imgSrc: blog_3,
    badge: "Market Insights",
    date: "20 Feb 2024",
    title: "Real Estate Market Cycles",
    description:
      "Learn about real estate market cycles, their stages, and how each phase affects property values and investment choices.",
    link: "blog-details.html",
  },
  {
    id: 4,
    imgSrc: blog_4,
    badge: "Home Buying",
    date: "10 Jan 2024",
    title: "Tips for First-Time Buyers",
    description:
      "Essential advice for first-time homebuyers, covering budgeting, mortgage options, and finding the perfect property.",
    link: "blog-details.html",
  },
];

const AdvantageSection = () => {
  return (
    <section className="companyWork my-5 mt-0 mt-5 ">
      <div className="text-content container">
        <div>
          <h2 className="text-white">Blogs</h2>
          <h5 className="text-lg-center ku mt-2">
            Stay updated with the Real Estate industry
          </h5>
        </div>
      </div>
      <div className="container">
        <div className="row center-item">
          {blogData.map((blog) => (
            <div key={blog.id} className="col-6 col-lg-3 my-md-3 col-md-6 p-2">
              <div className="project-Card-container">
                <img
                  alt={blog.title}
                  src={blog.imgSrc}
                  loading="lazy"
                  className="w-100"
                />
                <h6 className="mt-1 fw-light mt-4 text-center ">
                  {blog.title}
                </h6>
                <p className=" ">{blog.description}</p>
                <button className="w-100 mt-auto d-flex bg-transparent border rounded-4 mx-auto">
                  <Link
                    to="/blog"
                    className="del-underLine w-100 py-1 text-decoration-none text-white"
                  >
                    Explore
                  </Link>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AdvantageSection;
