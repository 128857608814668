import React from "react";
import DynamicBanner from "../../Components/DynamicBanner/Dynamic";
import aboutBG from "../../assets/About.jpg";
const TermsConditions = () => {
  return (
    <div>
      <DynamicBanner
        backgroundImage={aboutBG}
        heading="Terms And Condition"
        subheading="If you are in search of a top-tier property management company in (Location) ."
      />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-10 pt-5">
              <h3>1. GENERAL</h3>

              <p>
                This document is an electronic record in terms of Information
                Technology Act, 2000 and rules there under as applicable and the
                amended provisions pertaining to electronic records in various
                statutes as amended by the Information Technology Act, 2000.
                This electronic record is generated by a computer system and
                does not require any physical or digital signatures.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsConditions;
