import React, { useEffect, useState, useRef } from "react";
import { startCountAnimation } from "../../../utils/CountAnimation/CountAnimation";
import useOnScreen from "../../../Hooks/useOnScreen";
import { ReactComponent as VideoIcon } from "../../../icons/video.svg";
import GLightbox from "glightbox";
import about_approach_img from "../../../assets/about-our_approach.png";
import about_journey_img from "../../../assets/about_journey_img2.png";

import "glightbox/dist/css/glightbox.min.css";
import "./Approachs.css";

const amenitiesData = [
  {
    amount: 170,
    description: "Owned from Properties transactions",
    iconClass: "bi bi-house",
  },
  {
    amount: 20,
    description: "Properties for Buy & sell Successfully",
    iconClass: "bi bi-shop",
  },
  {
    amount: 400,
    description: "Daily completed transactions",
    iconClass: "bi bi-calendar-check",
  },
  {
    amount: 200,
    description: "Regular Clients",
    iconClass: "bi bi-person-check",
  },
];
const teamData = [
  {
    name: "Vin Diesel",
    role: "Senior App Developer",
    image:
      "https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg",
  },
  {
    name: "David Corner",
    role: "Front End Developer",
    image:
      "https://1.bp.blogspot.com/-vhmWFWO2r8U/YLjr2A57toI/AAAAAAAACO4/0GBonlEZPmAiQW4uvkCTm5LvlJVd_-l_wCNcBGAsYHQ/s16000/team-1-2.jpg",
  },
  {
    name: "Tom Cruise",
    role: "Full Stack Developer",
    image:
      "https://1.bp.blogspot.com/-AO5j2Y9lzME/YLjr3mxiqAI/AAAAAAAACPE/KAaYYTtQTrgBE3diTbxGoc4U4fCGx-C2gCNcBGAsYHQ/s16000/team-1-4.jpg",
  },
  {
    name: "Tom Cruise",
    role: "Full Stack Developer",
    image:
      "https://1.bp.blogspot.com/-AO5j2Y9lzME/YLjr3mxiqAI/AAAAAAAACPE/KAaYYTtQTrgBE3diTbxGoc4U4fCGx-C2gCNcBGAsYHQ/s16000/team-1-4.jpg",
  },
  {
    name: "David Corner",
    role: "Front End Developer",
    image:
      "https://1.bp.blogspot.com/-vhmWFWO2r8U/YLjr2A57toI/AAAAAAAACO4/0GBonlEZPmAiQW4uvkCTm5LvlJVd_-l_wCNcBGAsYHQ/s16000/team-1-2.jpg",
  },
  {
    name: "Vin Diesel",
    role: "Senior App Developer",
    image:
      "https://1.bp.blogspot.com/-8c7QTLoyajs/YLjr2V6KYRI/AAAAAAAACO8/ViVPQpLWVM0jGh3RZhh-Ha1-1r3Oj62wQCNcBGAsYHQ/s16000/team-1-3.jpg",
  },
];

const Approachs = () => {
  const [counts, setCounts] = useState(amenitiesData?.map(() => 0));
  const sectionRef = useRef(null);

  // Use the custom hook to track visibility
  const isVisible = useOnScreen(sectionRef);

  useEffect(() => {
    if (isVisible) {
      // Reset counts to 0 before starting the animation
      setCounts(amenitiesData?.map(() => 0));
      startCountAnimation(amenitiesData, setCounts);
    }
  }, [isVisible]); // Trig

  useEffect(() => {
    GLightbox({
      selector: ".glightbox",
    });
  }, []);

  return (
    <>
      <section>
        <div className="container-sm container-md container-xl container-lg container-xxl">
          <div className="row pt-3 pt-md-5">
            <div className="col-md-6 col-lg-6">
              <div
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={150}
              >
                <h2 className="section__heading--title  pb-1">Our Approach</h2>
                <p>
                  All successful real estate endeavors begin with a vision;
                  however, without the right management, even the greatest plans
                  encounter hurdles. WPM India enters the field to ease property
                  management so that developers and investors may focus on their
                  core business—portfolio development. Our philosophy is
                  straightforward:
                </p>
                <ul>
                  <li className="mb-2">
                    Eliminate a challenge in operations; we take care of
                    property management and do not have the wrinkles that slow
                    down the progress.
                  </li>
                  <li className="mb-2">
                    Increase efficiency and profit more with strategic insights
                    that unlock your assets to maximize ROI.
                  </li>
                  <li className="mb-2">
                    Guarantee constant and sustainable success with
                    forward-thinking solutions that will result in continued
                    growth.
                  </li>
                </ul>
                <p>
                  We bring to property management the human touch and
                  intelligent strategies beyond the people- therefore give value
                  to properties, improving tenant experience, and future-proof
                  investments. WPM India believes that property management
                  should be easy, profitable, and customized to suit your goals.
                  We've got all the expertise and support needed for successful
                  investing. Your real estate vision will become a reality-one
                  seamless operation at a time.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div
                className="  "
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={100}
              >
                <div className="  position-relative ">
                  <img
                    src={about_approach_img}
                    alt="Day view of the royal palace"
                    className=""
                  />
                  <div
                    className="position-absolute  top-50"
                    style={{ right: "20%" }}
                  >
                    {/* <img
                    src="/assets/analog-landscape-city-with-buildings 1.jpg"
                    alt="Analog landscape of a city with buildings"
                    className="aboutTwo"
                  /> */}
                    <div className="  ">
                      <a
                        className="bideo__play--icon glightbox"
                        href="https://player.vimeo.com/video/115041822#t=5s"
                        data-gallery="video"
                      >
                        <VideoIcon />
                        <span className="visually-hidden">Video Play</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ backgroundColor: "rgba(251, 244, 244, 1)", marginTop: "72px" }}
        data-aos="fade-up"
        data-aos-duration={1200}
        data-aos-delay={100}
      >
        <div className="container">
          <div className="row pt-5 pb-0">
            <div className="col-md-4 d-flex mt-auto">
              <img
                src={about_journey_img}
                alt="removebg-preview_1-removebg-preview.png"
              />
            </div>
            <div className="col-md-7">
              <h2 className="section__heading--title">Our Journey So Far</h2>
              <p>
                At WPM, we have set out to reimagine real estate as a one-stop
                platform for any property-related need; with LiveEasy, we take
                that vision a step further by bridging buyers, sellers, and
                renters with easy transactions and hassle-free experiences.
              </p>
              <p>
                Wherever one may be in the whole process of buying, selling,
                renting, or investing-the ultimate aim is to make the process
                simple, intelligent, and free of hassle.
              </p>

              <div className="row pt-4" ref={sectionRef}>
                {amenitiesData?.map((item, index) => (
                  <div key={index} className="col-md-3 col-6 mb-30">
                    <div className="amenities__icone text-center">
                      <span>
                        <i className={item.iconClass}></i>
                      </span>
                    </div>
                    <h2 className="text-dark text-center">{counts[index]}+</h2>
                    <p className="text-dark text-center pt-1">
                      {item.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="contacts ">
        <div className="container-sm container-md container-xl container-lg container-xxl">
          <div className="row mt-5 mb-5">
            <div className="col-md-12">
              <h2 className="text-center mt-3 mb-3">Our Team</h2>
            </div>
            {teamData?.map((member, index) => (
              <div className="col-md-4 col-6 mb-5 " key={index}>
                <div className="profile-card">
                  <div className="img">
                    <img src={member.image} alt={member.name} />
                  </div>
                  <div className="caption">
                    <h3>{member.name}</h3>
                    <p>{member.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Approachs;
