import React from "react";
import BannerSection from "./BannerSection/BannerSection";
import Approach from "./Approach/Apptoach";
import PopularCites from "./PopularCites/PopularCites";
import Regular from "./Regular/Regular";
import Service from "./Service/Service";
import CityRealEstateExplorer from "./CityRealEstateExplorer/CityRealEstateExplorer";
import JoinAsRealEstateAgent from "./JoinAsRealEstateAgent/JoinAsRealEstateAgent";
import TestMonials from "./TestMonials/TestiMonials";
import PropertyActionCards from "./PropertyActionCards/PropertyActionCards";
import AdvantageSection from "./AdvantageSection/AdvantageSection";
import { Link } from "react-router-dom";
// import SwiperSlider from '../../Components/SwiperSlider/SwiperSlider'
// import { ReactComponent as HouseIcons } from "../../icons/house.svg"

const Home = () => {
  return (
    <>
      <BannerSection />
      <Approach />
      <Regular />
      {/* <PropertyActionCards /> */}

      {/* <PopularCites /> */}
      <Service />

      <TestMonials />
      <AdvantageSection />

      <CityRealEstateExplorer />
      <JoinAsRealEstateAgent />
    </>
  );
};

export default Home;
