import React from "react";
import "./Dynamic.css";
const Dynamic = ({ backgroundImage, heading, subheading }) => {
  console.log(backgroundImage, heading, subheading);
  return (
    <div
      className="background-section d-flex justify-content-center align-items-center text-center "
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div>
        <h1 className="text-white">{heading}</h1>
        {/* <p className="text-white fs-6 fs-md-5">{subheading}</p> */}
      </div>
    </div>
  );
};

export default Dynamic;
