import service1 from "../../assets/property/featured-grid3.jpg";
import service2 from "../../assets/property/featured-grid2.jpg";

const servicesData = [
  {
    id: 1,
    title: "Licensing Compliance",
    slug: "licensing-compliance", // ✅ Slug added
    description:
      "At WPM India, our Licensing Compliance service ensures that all your property management practices adhere to industry standards.",
    image: "/assets/property/featured-grid3.jpg",
    backgroundImage: "/assets/advisory_bg.jpg",
    features: ["Legal Support", "Regulatory Compliance", "Expert Consultation"],
    galleryImage: "/assets/gallery/licensing.jpg",
  },
  {
    id: 2,
    title: "Transaction Advisory Solution",
    slug: "transaction-advisory-solution", // ✅ Slug added
    image: service2,
    content: `<h3>Transaction Advisory Solution: A Gateway of Every Transaction Issues</h3>
    <p>No one is aware of your enterprise the way you do. You have put in many years of hard work and sustained efforts into nurturing your enterprise, and operations. Selling a business and doing it properly calls for exceptionally experienced professionals with specialised abilities. units.</p>
    <p>That's in which <strong>Transaction Advisory Solution</strong> could make all the distinction. At WPM India, our professional Transaction Advisory Solution professionals have industry and technical expertise to offer realistic, independent, and facts-centric financial deals or capital marketplace hobby.</p>
    <h3>What is Our Transaction Advisory Solution?</h3>
    <p>At WPM India, our Transaction Advisory Solution encompasses a set of specialised expert offerings for business transactions. Our services are tailor-made to support customers in the course of the entire deal lifecycle, from initial approach development to post-transaction integration.</p>
    <p>
This answer usually consists of monetary due diligence, where specialists meticulously analyze the economic fitness and risks related to a target company or asset.  Our aim to empower customers with comprehensive insights, strategic steerage, and operational help to navigate transactions effectively even as maximizing value and minimizing risks.
</p>
<h3>Key Advantages of Our Transaction Advisory Solution</h3>
<ul>
<li>
<strong>Expertise and Insight
</strong>
Our Transaction Advisory Solution professionals bring specialized knowledge and experience, providing valuable insights into market trends, industry dynamics, and transaction best practices. We help businesses navigate complex transactions more effectively and make informed decisions.

</li>
<li>
<strong>
Value Maximization
</strong>
Through rigorous financial analysis and valuation techniques, we help businesses assess the true value of target companies or assets. This enables clients to negotiate better terms, structure deals more effectively, and maximize transaction value.
</li>
<li>
<strong>
Strategic Guidance
</strong>
Our Transaction Advisory Solution's experts offer strategic advice and guidance throughout the transaction process, helping businesses develop and execute successful growth strategies. From market entry to post-merger integration, we assist clients in achieving their long-term business objectives.
</li>
<h3>
Looking for the Best Transaction Advisory Solution? We'll Help
</h3>
<p>If you are ready to unlock your business's full potential and embark on a growth journey, look no further than WPM India. With our <strong>Transaction Advisory Solution </strong>backed by years of industry experience and a track record of success, we are here to guide you every step of the way. So, visit our website or call us today.</p>
</ul>`,
  },
  {
    id: 3,
    title: "Facility management",
    slug: "facility-management",
    image: service1,
    content: `<h3>Facility management: Ensure the Safety and Security of Employees and Visitors</h3>
    <p>Facility management ensures the functionality, comfort, safety, and efficiency of buildings, infrastructure, and real estate. It involves coordinating the demand and supply of facilities and services related to real property and buildings.</p>
    <p>
You come to the right place if you are looking for a professional <strong>Facility Management </strong>solution. At WPM India, our solution is responsible for planning, developing, constructing, and maintaining all buildings and grounds owned or leased by the organization. We are responsible for the security of all organization buildings and grounds and the safety of all employees, visitors, and contractors.
</p>
<h3>Our Duties and Roles</h3>
<p>At WPM India, our professional Facility Management experts can vary depending on the organization and the property they manage. Here are some special duties and roles of our professionals:</p>
<ul>
<li>
Plans and coordinates the economical and effective facility layout.
</li>
<li>Oversees the upkeep and operation of the organization's infrastructure.</li>
<li>Maintains a high level of adherence to several rules and regulations.</li>
<li>Makes certain that the required licenses and permits are obtained.</li>
<li>Maintains connections and negotiates contracts with suppliers.</li>
<li>Calculates the cost of labor and materials.</li>
<li>Oversees the general upkeep and operation of the organization's facilities.</li>
</ul>

<h3>The Importance of Facility Management in Estate Industry</h3>
<p>Facility Management is one of the most critical functions in the Estate industry. It underpins the efficient operation, maintenance, or improvement of built environments. It has multiple dimensions and overall corporate success. Here are some important points to indicate the importance of Facility Management:</p>
<ul>
<li>
<strong>Ensure the Safety and Security of Employees and Visitors</strong>At WPM India, our Facility Management specialists ensure that facilities are safe and secure for employees and visitors. This includes conducting regular inspections, implementing safety procedures, and responding to incidents.
</li>
<li>
<strong>
Cost Management
</strong>
Effective facility management helps control operational costs through preventative maintenance, energy efficiency, and strategic planning, leading to significant savings over time.
</li>
<li>
<strong>
Health and Safety
</strong>
Facilities must comply with safety regulations. Facility managers ensure that properties meet health standards, conduct regular inspections, and implement safety protocols to protect occupants.
</li>
<li>
<strong>
Tenant Satisfaction
</strong>
Well-managed facilities contribute to tenant comfort and satisfaction. Prompt maintenance and a clean, well-organized environment foster positive relationships and encourage tenant retention.

</li>
</ul>
<h3>Why Choose WPM India?</h3>
<p>At WPM India, We offer more than just conventional consulting. We give investors and developers strategic direction, fostering success with creative solutions. Our work culture is defined by our unrelenting dedication to excellence and transparency, guaranteeing that every project reaches its maximum potential and produces exceptional outcomes. For more information, visit our website and book a consultation with us.</p>




`,
  },
  {
    id: 4,
    title: "Parking Facility Management",
    slug: "parking-facility-management",
    image: service1,
    content: `<h3>WPM India: A Great Choice to Made Parking Facility Management Easy</h3>
    <p>In the world of infrastructure, proper parking control performs a critical function. These parking management services are designed to optimize space utilization, beautify personal comfort, and ensure seamless operation of parking centers.</p>
    <p>WPM India, a leader in <strong>Parking Facility Management</strong> , provides comprehensive parking management offerings that blend superior generations with expert control. Our professional team is dedicated to offering revolutionary solutions tailor-made to fulfill the specific wishes of your facility.</p>
    <h3>What is Our Parking Facility Management?</h3>
    <p>Our Parking Facility Management is designed to resource the parking enterprise in going for walks more easily and successfully. It is an exquisite choice for handling parking lots because it is designed to relieve people's parking woes and offers several useful features. Our Parking Facility Management can manage many automated gates, bearing in mind convenient entrance and exit manage.</p>
    <h3>Why Choose WPM India?</h3>
    <p>
At WPM India, we apprehend the complexities of coping with parking centers. Our services are designed to optimize operations, ensuring an unbroken revel for facility managers and users. Here's what sets us apart:
</p>
<ul>
<li>
<strong>Tailored Solutions</strong>We understand that each parking facility is exceptional. Our method entails assessing your unique necessities to broaden customized control strategies that decorate operational performance and improve consumer pride.
</li>
<li><strong>Advanced Technology</strong>We use modern-day technology to offer smart parking solutions that integrate actual-time facts analytics, computerized payment systems, and mobile app functionalities. Our contemporary structures no longer streamline operations; however also provide customers with a hassle-unfastened parking experience.</li>
<li><strong>Professional Management</strong>Our crew of skilled experts is devoted to ensuring your facility operates easily. From staffing and education to renovation and security, we cope with all parking control elements, allowing you recognition in your center enterprise activities.</li>
<li><strong>Sustainability Initiatives</strong>At WPM India, we are dedicated to promoting sustainable practices. Our eco-friendly solutions encompass electric vehicle charging stations and efficient waste management systems, supporting your facility's alignment with cutting-edge environmental requirements.</li>
</ul>
    <h3>Contact Us Today!</h3>
    <p>Are you prepared to turn your parking lot into a profitable and well-run asset? To learn more about our Parking Facility Management solutions, contact WPM India right now. Allow us to assist you in developing a smooth parking experience that maximizes your facility's possibilities while satisfying your users' demands.</p>
    
    `,
  },
  {
    id: 5,
    title: "Ambiance Management",
    slug: "ambiance-management",
    image: service1,
    content: `
      <h3>Create a Welcoming Atmosphere with Our Ambiance Management Solutions</h3>
      <p>
        Ambiance Management has become a vital concern for hospitality managers worldwide. Despite the wide management interest, there needs to be more empirical research that addresses ambiance and its role in hospitality settings.
      </p>
      <p>
        Welcome to WPM India, where we specialize in creating exceptional ambiance management solutions tailored to enhance any environment. If you are in the hospitality, retail, or corporate sectors, we are here to transform your spaces into inviting atmospheres that resonate with your audience.
      </p>
      
      <h3>Why Ambiance Management Matters?</h3>
      <p>
        Ambiance Management plays an important role in shaping the perceptions and emotions of visitors. It encompasses lighting, sound, scent, and overall design, collectively creating a unique atmosphere.
      </p>
      <p>
        At WPM India, a well-crafted ambiance enhances the aesthetic appeal, drives engagement, and improves overall well-being. We take a holistic approach to ambiance management. Our team of experts works closely with you to understand your vision, brand identity, and target audience.
      </p>
  
      <h3>Our Special Ambiance Management Services</h3>
      <ul>
        <li>
          <strong>Lighting Design:</strong> Our lighting professionals design tailor-made lighting fixture schemes that enhance the mood and functionality of your space. From gentle, warm lighting for cozy surroundings to bright, vibrant settings for active environments, we ensure the perfect illumination.
        </li>
        <li>
          <strong>Soundscaping:</strong> Sound plays an enormous role in the atmosphere. We curate soundscapes that reflect your brand identity, whether it’s soothing background music for a serene setting or dynamic sounds for a lively environment.
        </li>
        <li>
          <strong>Scent Marketing:</strong> Harness the power of scent to evoke emotions and create lasting memories. Our scent marketing solutions are designed to engage the senses and strengthen your brand image.
        </li>
        <li>
          <strong>Interior Design Consultation:</strong> Our design professionals offer consultation services to help you select furniture, colors, and decor that complement your ambiance goals, ensuring a cohesive and welcoming environment.
        </li>
      </ul>
  
      <h3>Get Started Today!</h3>
      <p>
        Transform your space with WPM India's expert ambiance management solutions. Contact us today to discuss how we can help you create an inviting atmosphere that enhances experiences and leaves a lasting impression.
      </p>
      
      <p><strong>WPM India – Crafting Ambiance, Creating Experiences!</strong></p>
    `,
  },

  {
    id: 6,
    title: "Business Support Management",
    slug: "business-support-management",
    image: service1,

    content: `
      <p>Starting a new business is an exciting journey filled with endless possibilities. But the tasks involved in launching and growing a start up can be overwhelming, especially if you are going at it alone. That's where a <strong> Business Support Management service</strong> comes in.</p>
      <p>At WPM India, our Business Support Management service provides invaluable assistance and guidance while you navigate the challenges of starting a new venture. We also cover advanced tips to help you choose the right support services for your unique needs.</p>

      <h3>By the Way, What is Our Business Support Management Service?</h3>
      <p>At WPM India, our <strong>Business Support Management </strong>service includes consulting, logistics, product help desk, and technical support. We cover effectively all business related services that can be outsourced. Our service facilitates the production or delivery of services to the organization's customers while supporting other internal functions. In essence, we enable the smooth functioning of the entire organization</p>

      <h3>Why Does Our Business Support Management Play an Important Role?</h3>
      <p>At times, running a business is like balancing on a unicycle while juggling flaming torches. It is thrilling, but it can get seriously scary. From managing daily operations to chasing growth goals, you are constantly multitasking.</p>
      <p>With the ever-growing pile of administrative tasks, financial deadlines, and the eternal quest to win the hearts of your customers, a lot is going on, which is why our Business Support Management services are pivotal to your success. Our services are necessary, especially during the critical starting and scaling phase. Here are the important role of our Business Support Management service:</p>

  <ul style={{margin}}>
  <li ><strong>Experts Guidance</strong>  At WPM India, our professional Business Support Management experts provide access to professionals with specific expertise in different areas. We provide valuable guidance, best practices, and insights to help you navigate challenges and make informed decisions</li>
  <li><strong>Cost Effective Solutions</strong> Hiring full-time employees for every function can be costly. We offer a cost-effective alternative, allowing you to access necessary expertise without the financial burden of full-time salaries and benefits.</li>
  <li><strong>Increased Revenue</strong> With the help of our Business Support Management service, you will get revenue-generating opportunities. It analyzes customer usage patterns and preferences, which can be used to develop targeted marketing campaigns and personalized offers.</li>
  </ul>
     <h3>Get in Touch with Us!</h3>
     <p>If you are looking for a professional <strong>Business Support Management </strong> service for your business, look no further than WPM India. We offer the flexibility to scale up or down based on fluctuating business needs. Our adaptability ensures that companies can respond promptly to market or operational requirements changes. So, if you want to hire us, visit our website or call us today. We'll be happy to assist you!</p>
    `,
  },
  // 🔽 Baaki services ke liye bhi slug add karo
];

export default servicesData;
