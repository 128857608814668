import React from "react";
import "./Banner.css";
import "../Service/Service.css";
import backgroundVideo from "../../../assets/video/Property.mp4";

const amenitiesData = [
  { text: "19+ Years of Experience", iconClass: "bi bi-award" },
  { text: "10+ Cities", iconClass: "bi bi-geo-alt" },
  { text: "200+ Projects", iconClass: "bi bi-building" },
  { text: "5000+ Clients", iconClass: "bi bi-people" },
  { text: "100+ Awards", iconClass: "bi bi-trophy" },
  { text: "24/7 Support", iconClass: "bi bi-headset" },
];

const BannerSection = () => {
  return (
    <>
      <div className="hero__section hero__section--bg">
        {/* Background Video */}
        <video autoPlay loop muted className="hero__video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="container position-relative  h-100  d-flex align-items-center justify-conent-center">
          <div className="row ">
            <div className="col-md-12 text-center">
              <h1
                className="text-white fs-1"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={100}
              >
                Your Property, Our Passion
              </h1>
              <p
                className="text-white d-none d-md-block"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={50}
              >
                <strong>
                  Looking for India’s Most Trusted Property Partner?{" "}
                </strong>
                You’ve just found your match. At <strong>WPM India, </strong>we
                nurture properties, not just manage them. For over 19 years, we
                have turned heads in the real estate industry into success
                stories for <strong>5,000+ property owners, residents,</strong>{" "}
                and investors across 10+ cities.
              </p>
              {/* <p
                className="text-white d-none d-md-block"
                data-aos="fade-up"
                data-aos-duration={1200}
                data-aos-delay={50}
              >
                The "Welcome" should start from the second line and similarly
                the line "We specialize" from the third line and the
                introduction should be more precise.
              </p> */}
            </div>
            <div className="col-12 w-100 d-none d-md-flex flex-wrap justify-content-center mt-4">
              {amenitiesData?.map((amenity, index) => (
                <div
                  key={index}
                  className="col-md-2 d-flex flex-column align-items-center"
                  data-aos="fade-up"
                  data-aos-duration={1200}
                  data-aos-delay={100}
                >
                  <div className="amenities__icone text-center">
                    <span>
                      <i
                        className={amenity.iconClass}
                        style={{ fontSize: "2rem" }}
                      ></i>
                    </span>
                    <p className="text-white text-center fs-5 lh-sm mt-2">
                      {amenity.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Icons Row (Desktop) */}
        </div>
      </div>

      <div className="row d-flex justify-content-center pt-5 d-block d-md-none">
        {amenitiesData?.map((amenity, index) => (
          <div
            key={index}
            className="col-4 col-sm-4 col-md-3 col-lg-2"
            data-aos="fade-up"
            data-aos-duration={1200}
            data-aos-delay={100}
          >
            <div className="amenities__icone text-center">
              <span>
                <i className={amenity.iconClass}></i>
              </span>
              <p className="text-md-white text-black text-center fs-6 lh-sm mt-3">
                {amenity.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BannerSection;
