import { FaRegCalendarAlt } from "react-icons/fa";
import Form from "../../Components/Form/Form";
import recent1 from "../../assets/property/featured1.jpg";
import recent2 from "../../assets/property/featured2.jpg";
import recent3 from "../../assets/property/featured3.jpg";
const recentPosts = [
  {
    id: 1,
    image: recent1,
    date: "Jan 01, 2024",
    title: "Budget Issues Force The Our To Become",
    link: "blog-details.html",
  },
  {
    id: 2,
    image: recent2,
    date: "Jan 01, 2024",
    title: "Tos lobortis des mollis ut risus",
    link: "listing-details.html",
  },
  {
    id: 3,
    image: recent2,
    date: "Jan 01, 2024",
    title: "Ut labore et dolore magna aliqua.",
    link: "listing-details.html",
  },
  {
    id: 4,
    image: recent3,
    date: "Jan 01, 2024",
    title: "Ut labore et dolore magna aliqua.",
    link: "listing-details.html",
  },
];

const BlogRecentPosts = () => {
  return (
    <div
      className=""
      data-aos="fade-left"
      data-aos-duration={1200}
      data-aos-delay={20}
    >
      <div className="p-3 border rounded shadow-sm bg-light mb-4">
        <h2 className="mb-3">Recent Posts</h2>
        <div>
          {recentPosts?.map((post) => (
            <div key={post.id} className="d-flex mb-3 align-items-center">
              <div className="me-3">
                <a href={post.link}>
                  <img
                    src={post.image}
                    alt="img"
                    className="img-fluid rounded"
                    style={{ width: "80px", height: "80px" }}
                  />
                </a>
              </div>
              <div>
                <span className="text-muted d-flex align-items-center">
                  <FaRegCalendarAlt
                    size={15}
                    className="me-2"
                    style={{ color: "#fa4a4a" }}
                  />{" "}
                  {post.date}
                </span>
                <h6 className="m-0">
                  <a
                    href={post.link}
                    className="text-decoration-none text-dark"
                  >
                    {post.title}
                  </a>
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogRecentPosts;
