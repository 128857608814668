import React from "react";
import Form from "../../Components/Form/Form";
import Dynamic from "../../Components/DynamicBanner/Dynamic";
import JoinAsRealEstateAgent from "../HeroSection/JoinAsRealEstateAgent/JoinAsRealEstateAgent";
// import Pagination from '../../Components/Pagination/Pagination';
import "./Blog.css";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaRegCalendarAlt } from "react-icons/fa";
import BlogRecentPosts from "./BlogRecentPosts";
import bloBG from "../../assets/advisory_bg.jpg";
import blog1 from "../../assets/blog/blog1.png";
import blog2 from "../../assets/blog/blog2.png";
import blog3 from "../../assets/blog/blog3.png";
const blogData = [
  {
    id: 1,
    imgSrc: blog1,
    badge: "Business",
    date: "02 Apr 2024",
    title: "The 8 Best Things About Real Estate",
    description:
      "Business is the activity of making or buying and selling products.",
    link: "blog-details.html",
  },
  {
    id: 2,
    imgSrc: blog2,
    badge: "Finance",
    date: "15 Mar 2024",
    title: "How to Save Money Effectively",
    description: "Finance is the science of managing funds.",
    link: "blog-details.html",
  },
  {
    id: 2,
    imgSrc: blog3,
    badge: "Finance",
    date: "15 Mar 2024",
    title: "How to Save Money Effectively",
    description: "Finance is the science of managing funds.",
    link: "blog-details.html",
  },
  {
    id: 2,
    imgSrc: blog2,
    badge: "Finance",
    date: "15 Mar 2024",
    title: "How to Save Money Effectively",
    description: "Finance is the science of managing funds.",
    link: "blog-details.html",
  },
  {
    id: 2,
    imgSrc: blog3,
    badge: "Finance",
    date: "15 Mar 2024",
    title: "How to Save Money Effectively",
    description: "Finance is the science of managing funds.",
    link: "blog-details.html",
  },
  {
    id: 2,
    imgSrc: blog1,
    badge: "Finance",
    date: "15 Mar 2024",
    title: "How to Save Money Effectively",
    description: "Finance is the science of managing funds.",
    link: "blog-details.html",
  },
  // Add more blog items as needed
];

const Blog = ({}) => {
  return (
    <>
      <Dynamic
        backgroundImage={bloBG}
        heading="Blogs"
        subheading="Learn more about our company and values."
      />

      <div className="container">
        <div className="row my-4">
          <h2 className="text-center">All Blogs</h2>
        </div>
        <div className="row pt-4 mb-2">
          <div className="col-md-8 ">
            <div className="row">
              {blogData?.map((blog, index) => (
                <div
                  className="col-md-6  mb-4 "
                  key={index}
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  data-aos-delay={20}
                >
                  <div className="card h-100 border shadow p-3 rounded-4 pb-0">
                    {/* Thumbnail */}
                    <div className="position-relative">
                      {/* <a href="blog-details.html"> */}
                      <img
                        className="card-img-top img-fluid rounded-4"
                        style={{ height: "200px" }}
                        src={blog.imgSrc}
                        alt="blog-img"
                      />
                      {/* </a> */}
                      <span
                        className="badge  position-absolute top-0 start-0 m-2"
                        style={{ backgroundColor: "#fa4a4a" }}
                      >
                        Business
                      </span>
                    </div>

                    {/* Blog Content */}
                    <div className="card-body d-flex flex-column  ps-0 ">
                      {/* Meta Info */}
                      {/* <ul className="list-unstyled d-flex align-items-center mb-2"> */}
                      <div className="d-flex  align-items-center gap-2    mb-2">
                        {/* <span className=""> */}
                        <FaRegCalendarAlt size={15} />
                        {/* </span> */}
                        <span>{blog.date}</span>
                      </div>
                      {/* </ul> */}

                      {/* Blog Title */}
                      <h5 className="card-title">
                        {/* <a
                          href="blog-details.html"
                          className="text-dark text-decoration-none"
                        > */}
                        {blog.title}
                        {/* </a> */}
                      </h5>

                      {/* Blog Description */}
                      <p className="card-text text-muted m-0">
                        {blog.description}
                      </p>

                      {/* Read More */}
                      <div className="mt-auto ">
                        <Link
                          to={`/BlogDetail/${blog.id}`}
                          className=" text-decoration-none  d-flex align-items-center gap-2"
                        >
                          Read more
                          <FaArrowRightLong />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div
            className="col-md-4"
            // style={{ paddingTop: "45px" }}
            data-aos="fade-left"
            data-aos-duration={1200}
            data-aos-delay={20}
          >
            <div className="listing__widget">
              <div className="blog__widget--step mb-30">
                <h2 className="widget__step--title">Recent Post</h2>
                <div className="widget__featured">
                  <div className="widget__featured--items d-flex">
                    <div className="widget__featured--thumb">
                      <a
                        className="widget__featured--thumb__link"
                        href="blog-details.html"
                      >
                        <img
                          className="widget__featured--media"
                          src="/assets/property/featured1.jpg"
                          alt="img"
                        />
                      </a>
                    </div>
                    <div className="widget__featured--content">
                      <span className="widget__featured--date">
                        <FaRegCalendarAlt size={15} />
                        Jan 01,2024
                      </span>
                      <h3 className="widget__featured--title m-0">
                        <a href="blog-details.html">
                          Budget Issues Force The Our To Become
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="widget__featured--items d-flex">
                    <div className="widget__featured--thumb">
                      <a
                        className="widget__featured--thumb__link"
                        href="listing-details.html"
                      >
                        <img
                          className="widget__featured--media"
                          src="/assets/property/featured2.jpg"
                          alt="img"
                        />
                      </a>
                    </div>
                    <div className="widget__featured--content">
                      <span className="widget__featured--date">
                        <FaRegCalendarAlt size={15} />
                        Jan 01,2024
                      </span>
                      <h3 className="widget__featured--title m-0">
                        <a href="listing-details.html">
                          tos lobortis des mollis ut risus
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="widget__featured--items d-flex">
                    <div className="widget__featured--thumb">
                      <a
                        className="widget__featured--thumb__link"
                        href="listing-details.html"
                      >
                        <img
                          className="widget__featured--media"
                          src="assets/property/featured3.jpg"
                          alt="img"
                        />
                      </a>
                    </div>
                    <div className="widget__featured--content">
                      <span className="widget__featured--date">
                        <FaRegCalendarAlt size={15} />
                        Jan 01,2024
                      </span>
                      <h3 className="widget__featured--title m-0">
                        <a href="listing-details.html">
                          ut labore et dolore magna aliqua.
                        </a>
                      </h3>
                    </div>
                  </div>
                  <div className="widget__featured--items d-flex">
                    <div className="widget__featured--thumb">
                      <a
                        className="widget__featured--thumb__link"
                        href="listing-details.html"
                      >
                        <img
                          className="widget__featured--media"
                          src="/assets/property/featured3.jpg"
                          alt="img"
                        />
                      </a>
                    </div>
                    <div className="widget__featured--content">
                      <span className="widget__featured--date">
                        <FaRegCalendarAlt size={15} />
                        Jan 01,2024
                      </span>
                      <h3 className="widget__featured--title m-0">
                        <a href="listing-details.html">
                          ut labore et dolore magna aliqua.
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Form />
          </div> */}
          <div className="col-lg-4">
            <BlogRecentPosts />
            <Form />
          </div>
        </div>
        {/* <Pagination /> */}
      </div>
      <JoinAsRealEstateAgent />
    </>
  );
};

export default Blog;
