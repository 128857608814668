import React, { useState } from "react";
import "./JoinAsRealEstateAgent.css";
import Esclipse from "../../../../src/images/subscriber/subscriber1.jpg";
import Esclipse1 from "../../../../src/images/subscriber/subscriber2.jpg";
import Esclipse2 from "../../../../src/images/subscriber/subscriber3.jpg";
import Esclipse3 from "../../../../src/images/subscriber/subscriber4.jpg";

const JoinAsRealEstateAgent = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [emailSubmitting, setEmailSubmitting] = useState(false);

  const handleSubscribe = async (event) => {
    event.preventDefault();
    setMessage("Thank You For Subscribing");
    setEmail("");
    setTimeout(() => setMessage(""), 1500);
  };

  return (
    <section className="subscribe-section py-5">
      <div className="container-lg ">
        <div className="row align-items-center ">
          <div
            className="col-md-6 d-flex flex-column "
            // data-aos="fade-down-right"
            // data-aos-duration={2000}
            // data-aos-delay={300}
            // data-aos-easing="ease-in-out"
          >
            <h2 className="fw-bold mb-3 fs-4 text-center">
              Subscribe to“Be the First to Know”
            </h2>
            <p className="mb-4 text-center">
              Get exclusive property insights, market trends, and WPM success
              stories – straight to your inbox.
            </p>

            <form
              // style={{ width: "100%", backgroundColor: "white" }}
              onSubmit={handleSubscribe}
              className="rounded-pill"
            >
              <div className="d-flex   ">
                <input
                  type="email"
                  className="form-control rounded-start-pill text-black"
                  placeholder="Your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    // padding: "10px 20px",
                    paddingRight: "100px",
                    width: "100%",
                    border: "none",
                    color: "black !important",
                  }}
                  required
                />
                <button
                  type="submit"
                  className="btn-orange rounded-end-pill fs-6 px-3"
                  // style={{
                  //   padding: "10px 48px",
                  //   border: "none",
                  // }}
                >
                  {emailSubmitting ? "Subscribing..." : "Subscribe"}
                </button>
              </div>
            </form>

            {message && (
              <p className=" mt-2" style={{ color: "#ef750f" }}>
                {message}
              </p>
            )}
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="image-grid">
              <div className="row">
                <div
                  className="col-6 mb-3"
                  data-aos="flip-up"
                  data-aos-duration={2000}
                  data-aos-delay={300}
                  data-aos-easing="ease-in-out"
                >
                  <img
                    src={Esclipse}
                    alt="Building 1"
                    className="img-fluid "
                    style={{ marginTop: "22px" }}
                  />
                </div>
                <div
                  className="col-6 mb-3"
                  data-aos="flip-right"
                  data-aos-duration={2000}
                  data-aos-delay={300}
                  data-aos-easing="ease-in-out"
                >
                  <img
                    src={Esclipse1}
                    alt="Interior"
                    className="img-fluid mt-3"
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-6"
                  data-aos="flip-down"
                  data-aos-duration={2000}
                  data-aos-delay={300}
                  data-aos-easing="ease-in-out"
                >
                  <img src={Esclipse2} alt="Building 2" />
                </div>
                <div
                  className="col-6"
                  data-aos="flip-up"
                  data-aos-duration={2000}
                  data-aos-delay={300}
                  data-aos-easing="ease-in-out"
                >
                  <img src={Esclipse3} alt="Building 3" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinAsRealEstateAgent;
