import React from "react";
import DynamicBanner from "../../Components/DynamicBanner/Dynamic";
import { ReactComponent as CircleIcons } from "../../icons/circle.svg";
import advisoryBG from "../../assets/advisory_bg.jpg";
import advisory_img1 from "../../assets/office-buildings (1) 1.jpg";
import advisory_img2 from "../../assets/managers-analyzing-sales-results 1.jpg";
import advisory_img3 from "../../assets/advisory-why_choose_us.png";
import advisory_img4 from "../../assets/property/featured-grid1.jpg";
const amenitiesData = [
  {
    amount: "Healthcare Facilities",
    description: "Owned from Properties transactions",
    iconClass: "bi bi-hospital",
  },
  {
    amount: "Residential & Commercial Real Estate",
    description: "Properties for Buy & sell Successfully",
    iconClass: "bi bi-buildings",
  },
  {
    amount: "Educational Campuses",
    description: "Daily completed transactions",
    iconClass: "bi bi-mortarboard",
  },
  {
    amount: "Retail Centers & Corporate Spaces",
    description: "Regular Clients",
    iconClass: "bi bi-briefcase",
  },
];

const AdvisoryManagement = () => {
  return (
    <>
      <DynamicBanner
        backgroundImage={advisoryBG}
        heading="Advisory Management"
        subheading="Learn more about our company and values."
      />

      <section className="about__section about__page--section section--padding mt-4 ">
        <div className="container">
          <div
            className="row "
            data-aos="fade-up"
            data-aos-duration={1200}
            data-aos-delay={100}
          >
            <div className="col-md-7 col-lg-6 ">
              <h2 className="pt-lg-5 ">
                Strategic Financial Solutions for a Secure Future
              </h2>
              <p className="lh-lg">
                At WPM India, we are about customized financial strategies that
                promise success in the long term. We offer comprehensive
                advisory management services that cover tax planning, investment
                management, insurance, pension planning, and property asset
                management. Whether wealth generation or optimization of your
                real estate portfolio is your concern, we provide expert
                solutions just for you.
              </p>
            </div>
            <div className="col-md-5 col-lg-6 d-flex align-items-center justify-content-center">
              <div className="position-relative overflow-hidden pe-0 approachimage ps-lg-4 ">
                <img
                  style={{
                    position: "relative",
                    zIndex: "2",
                    borderRadius: "12px",
                  }}
                  className="p-lg-5"
                  src={advisory_img1}
                  alt="Office buildings"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about__section about__page--section section--padding">
        <div className="container">
          <div
            className="row pt-md-4 d-flex"
            data-aos="fade-up"
            data-aos-duration={1200}
            data-aos-delay={100}
          >
            {/* Image Column */}
            <div className="col-md-5 col-lg-6 order-last order-md-first pt-lg-5 d-flex align-items-center justify-content-center">
              <div className="position-relative overflow-hidden p-md-5  approachimage">
                <img
                  style={{
                    position: "relative",
                    zIndex: "2",
                    borderRadius: "12px",
                  }}
                  src={advisory_img2}
                  alt="Managers analyzing sales results"
                />
              </div>
            </div>
            {/* Text Column */}
            <div className="col-md-7 pt-lg-5 col-lg-6 order-first order-md-last">
              <h2 className="mt-lg-5 mt-0">What is Advisory Management?</h2>
              <p className="lh-lg">
                Advisory management is about delivering expert investment
                solutions in tune with every client’s financial objectives.
                Individuals can rely on our specialists to guide them through
                market trends, risk assessment, and growth strategies when
                making an important investment decision.
              </p>
              <p className="lh-lg">
                With a wealth of experience across varying investment
                specializations, WPM India advisors provide tailored financial
                plans that suit every requirement- retirement planning, growing
                business assets, or property investments.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about__section about__page--section section--padding">
        <div className="container">
          <div
            className="row pt-md-4 d-flex"
            data-aos="fade-up"
            data-aos-duration={1200}
            data-aos-delay={100}
          >
            <div className="col-md-5 col-lg-6 order-last order-md-first ">
              <div className=" ">
                {/* <div className="about__thumbnail--list one position-relative">
                  <img
                    src="/assets/https___freepicdownloader 3.jpg"
                    alt="about-thumb"
                    className="about-thumb"
                  />
                </div> */}
                <div className="">
                  <img
                    src={advisory_img3}
                    alt="about-thumb"
                    style={{ borderRadius: "3px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-6 Chooseus  order-first order-md-last">
              <h2 className="Chooseus">Why Choose Us</h2>

              <p class="living__details--content__list pt-2">
                <CircleIcons style={{ marginRight: "10px" }} />A client-focused
                approach emphasizes experience and expectations.
              </p>

              <p class="living__details--content__list  pt-2">
                <CircleIcons style={{ marginRight: "10px" }} />A diversified
                range of services that guarantees holistic financial solutions.
              </p>
              <p class="living__details--content__list  pt-2">
                <CircleIcons style={{ marginRight: "10px" }} />
                Innovative strategies that maximize rewards and minimize risks.
              </p>

              <p class="living__details--content__list  pt-2">
                <CircleIcons style={{ marginRight: "10px" }} />
                High renewal and satisfaction rates from long-term clients.
              </p>
              <p class="living__details--content__list  pt-2">
                <CircleIcons style={{ marginRight: "10px" }} />
                Commitment to ethics, expertise, and results-based success.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 content-box">
        <div className="container">
          <div className="row p-lg-4">
            <div className="col-md-6">
              <h3 className="fw-semibold fs-1">Properties We deals in</h3>
              <div className="row pt-2">
                {amenitiesData?.map((item, index) => (
                  <div key={index} className="col-md-6 col-6">
                    <div className="amenities__icone text-center">
                      <span>
                        <i className={item.iconClass}></i>
                      </span>
                    </div>
                    <h5 className="text-dark text-center mb-2 fs-5">
                      {item.amount}
                    </h5>
                    {/* <p className='text-dark text-center'>{item.description}</p> */}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <img
                style={{ borderRadius: "8px" }}
                src={advisory_img4}
                alt="Thumbnail 2"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdvisoryManagement;
