import React, { useState, useRef, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Estate_img_1 from "../../../assets/1.jpg";
import Estate_img_2 from "../../../assets/blog/blog-page1.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CityRealEstateExplorer.css";

const propertyImages = [Estate_img_1, Estate_img_2];

const CityRealEstateExplorer = () => {
  const [propertyType, setPropertyType] = useState("Select Property Type");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const menuRef = useRef(null);

  const options = {
    "Job Enquiry": [],
    "Property Enquiry": ["Residential", "Commercial"],
    "Channel Partner Enquiry": [],
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="container py-5">
      <div className="row ">
        {/* Form Section */}
        <div
          className="col-lg-5 col-md-6 mb-4 "
          // data-aos="fade-up"
          // data-aos-duration={1200}
        >
          <h2 className="text-center fw-bold">Any Enquiries</h2>
          {/* <p className="text-center fs-5">Fill This Query Form</p> */}
          <div className="card p-4 shadow-sm rounded-3 mt-5">
            <form>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Full Name"
              />
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Last Name"
              />
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Budget"
              />
              <PhoneInput
                country="in"
                className="form-control mb-3"
                placeholder="Phone Number"
              />
              {/* Dropdown Menu */}
              <div className="position-relative mb-3" ref={menuRef}>
                <div
                  className="form-control d-flex justify-content-between align-items-center"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  {propertyType} <MdKeyboardArrowDown size={24} />
                </div>
                {isMenuOpen && (
                  <div className="dropdown-menu show w-100 shadow">
                    {Object.keys(options).map((category) => (
                      <div
                        key={category}
                        className="dropdown-item"
                        onClick={() => setPropertyType(category)}
                      >
                        {category}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <button className="btn btn-danger w-100 py-2 fw-bold rounded-5">
                Submit
              </button>
            </form>
          </div>
        </div>

        {/* Swiper Section */}
        <div
          className="col-lg-7 col-md-6 d-none d-md-block "
          data-aos="fade-up"
          data-aos-duration={1200}
        >
          <h2 className="fw-bold">Request a Call Back</h2>
          {/* <p>
            Looking for a home that brings happiness or a property that brings
            prosperity? Contact us - Get Real Estate with Unreal Deals!
          </p> */}
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop
            autoplay={{ delay: 2000 }}
            modules={[Navigation, Pagination, Autoplay]}
          >
            {propertyImages.map((image, index) => (
              <SwiperSlide key={index} className="mt-3">
                <img
                  src={image}
                  alt={`Property ${index}`}
                  className="img-fluid rounded-3"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CityRealEstateExplorer;
