import React from "react";
import { Link } from "react-router-dom";
import FooterCopyright from "../FooterCopyright/FooterCopyright";
import "./Footer.css";
import logo from "../../assets/wpm_logo.jpg";

function Footer() {
  return (
    <>
      <footer style={{ backgroundColor: "black" }}>
        <div className="container-fluid ">
          <div className="row m-0 p-3 p-lg-5 px-0 px-sm-4 ">
            <div className="col-md-3">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <img
                      src={logo}
                      alt="Logo"
                      style={{ borderRadius: "7px" }}
                    />
                  </div>
                </div>
                <h6 className="text-white mt-3 fs-6">
                  We believe in simplicity and thus address the complex
                  challenges of property operations through simplicity.
                </h6>
              </div>
              <div className="d-flex ltns__social-media col-12 justify-content-start pt-0 pt-md-3 ">
                <div className="text-danger fw-bold me-3 fs-6 fs-xl-5">
                  Follow Us
                </div>
                <ul
                  className="d-flex gap-3"
                  style={{ paddingLeft: 0, listStyle: "none" }}
                >
                  {[
                    { name: "facebook", url: "https://www.facebook.com" },
                    { name: "twitter", url: "https://www.twitter.com" },
                    { name: "linkedin", url: "https://www.linkedin.com" },
                    // { name: "youtube", url: "https://www.youtube.com" },
                  ].map((icon) => (
                    <li key={icon.name}>
                      <a
                        href={icon.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={
                          icon.name.charAt(0).toUpperCase() + icon.name.slice(1)
                        }
                      >
                        <i
                          className={`bi bi-${icon.name}`}
                          style={{ fontSize: "20px" }}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="col-md-9">
              <div className="row ">
                {[
                  {
                    title: "Company",
                    links: [
                      { to: "/", text: "Home" },
                      { to: "/about", text: "About" },
                      { to: "/blog", text: "Blog" },
                      // { to: "/properties", text: "All Properties" },
                      { to: "/contact-us", text: "Contact Us" },
                    ],
                  },
                  {
                    title: "Services",
                    links: [
                      { to: "/terms-condition", text: "Terms & Conditions" },
                      {
                        to: "/advisory-management",
                        text: "Advisory Management",
                      },
                      { to: "/service", text: "Technical Service" },
                      { to: "/disclamier", text: "Disclaimer" },
                      { to: "/privacy-policy", text: "Privacy Policy" },
                    ],
                  },
                  // {
                  //   title: "Featured Properties",
                  //   links: [
                  //     { to: "/SellProperty", text: "Sell" },
                  //     { to: "/buy", text: "Buy" },
                  //     { to: "/rent", text: "Rent" },
                  //     { to: "/lease", text: "Lease" },
                  //   ],
                  // },
                ].map((section, index) => (
                  <div
                    key={index}
                    className="col-md-4 col-6 d-flex  justify-content-start  justify-content-md-center"
                  >
                    <div className="footer-widget footer-menu-widget">
                      <h4 className="footer-title">{section.title}</h4>
                      <div className="footer-menu">
                        <ul style={{ paddingLeft: 0 }}>
                          {section.links.map((link, i) => (
                            <li key={i}>
                              <Link to={link.to}>{link.text}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="col-md-4 col-6 ">
                  <div className="footer-widget footer-newsletter-widget">
                    <h4 className="footer-title">Contact Us</h4>
                    <div className="footer-address">
                      <ul style={{ paddingLeft: 0, listStyle: "none" }}>
                        {[
                          {
                            icon: "geo-alt",
                            text: "C-70A, 7th Floor,Supermart-1,DLFphase 4,Gurgaon",
                          },
                          {
                            icon: "telephone",
                            text: (
                              <Link to="tel:+91 9311254752">
                                +91 9311254752
                              </Link>
                            ),
                          },
                          {
                            icon: "envelope",
                            text: (
                              <Link to="mailto:info@wpmindia.com">
                                info@wpmindia.com
                              </Link>
                            ),
                          },
                        ].map((item, idx) => (
                          <li
                            key={idx}
                            className="d-flex align-items-center gap-2 mb-3 mb-lg-5 "
                          >
                            <i
                              className={`bi bi-${item.icon}`}
                              style={{ fontSize: "16px" }}
                            />
                            <span className="text-white footer-menu lh-base">
                              {item.text}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterCopyright />
        </div>
      </footer>
    </>
  );
}

export default Footer;
