import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/wpm_logo.jpg";
import "./Header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false); // Close menu on NavLink click

  return (
    <div style={{ position: "sticky", top: "0px", zIndex: "10000" }}>
      <nav
        className="navbar navbar-expand-lg d-flex align-items-center px-2 px-lg-3"
        style={{ backgroundColor: "white" }}
      >
        <div className="header__logo">
          <NavLink to="/" onClick={closeMenu}>
            <img className="footer__logo--img" src={logo} alt="logo-img" />
          </NavLink>
        </div>

        {/* Header Icons */}
        <div className="d-flex align-items-center header-icons order-lg-2 position-relative">
          <NavLink
            to="/contact-us"
            className="btn outline rounded-pill px-2 py-1 px-md-4 py-md-2"
          >
            Contact Us
          </NavLink>
        </div>

        {/* Toggle Button */}
        <button
          className="navbar-toggler order-lg-3"
          type="button"
          onClick={toggleMenu}
          aria-expanded={isOpen}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div
          className={`collapse navbar-collapse order-lg-1 ${
            isOpen ? "show" : ""
          }`}
          id="navbarNav"
        >
          <ul className="navbar-nav justify-content-center">
            {[
              { name: "Home", path: "/" },
              { name: "About", path: "/about" },
              { name: "Advisory Management", path: "/advisory-management" },
              { name: "Technical Services", path: "/technical-service" },
              // { name: "Properties", path: "/properties" },
              { name: "Services", path: "/service" },
              { name: "Blog", path: "/blog" },
            ].map((link) => (
              <li className="nav-item" key={link.name}>
                <NavLink
                  to={link.path}
                  className={({ isActive }) =>
                    `nav-link ${isActive ? "active" : ""}`
                  }
                  onClick={() => {
                    closeMenu(); // Close menu on click
                  }}
                >
                  {link.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
