import React from "react";
import "./Services.css";
import { Link } from "react-router-dom";
// import Pagination from "../../Components/Pagination/Pagination";
import CallToActionSection from "../AboutSection/CallToActionSection/CallToActionSection";
import Dynamic from "../../Components/DynamicBanner/Dynamic";
import { FaArrowRightLong } from "react-icons/fa6";
import serviceBG from "../../assets/advisory_bg.jpg";
import service1 from "../../assets/property/featured-grid3.jpg";
import service2 from "../../assets/property/featured-grid2.jpg";

const services = [
  {
    id: 1,
    slug: "licensing-compliance",
    title: "Licensing Compliance",
    description:
      "Our property management operations comply with industry regulations and legal requirements. Our expert team navigates the entire licensing process, ensuring a risk-free business.",
    imageUrl: service1,
  },
  {
    id: 2,
    slug: "transaction-advisory-solution",
    title: "Transaction Advisory Solution",
    description:
      "Access our Transaction Advisory Solutions to make wise real estate decisions. Buy, sell, lease, or consolidate your assets while our experts assess asset value, verify conditions, and secure the best deal.",
    imageUrl: service2,
  },
  {
    id: 3,
    slug: "facility-management",
    title: "Facility Management",
    description:
      "Enhance property operations with expert facility management. We optimize maintenance, security, housekeeping, and asset upkeep using best practices and advanced technology.",
    imageUrl: service1,
  },
  {
    id: 4,
    slug: "parking-facility-management",
    title: "Parking Facility Management",
    description:
      "Optimize parking with our tailored parking management solutions. We design, operate, and maintain systems to improve traffic flow and efficiency for property owners and tenants.",
    imageUrl: service2,
  },
  {
    id: 5,
    slug: "ambiance-management",
    title: "Ambiance Management",
    description:
      "Enhance safety and aesthetics with our Ambiance Management services. We maintain property aesthetics while implementing high-security measures for assets, employees, and visitors.",
    imageUrl: service1,
  },
  {
    id: 6,
    slug: "business-support-management",
    title: "Business Support Management",
    description:
      "Streamline business operations with our Business Support Management services. From budgeting and financial transactions to IT solutions and system maintenance, we ensure seamless operations.",
    imageUrl: service2,
  },
];

const ServiceOverview = () => {
  return (
    <div className="">
      <Dynamic
        backgroundImage={serviceBG}
        heading="Our Services"
        subheading="Learn more about our company and values."
      />
      <div className="container  pt-5 mb-30 ">
        <div className="row">
          {services?.map((service) => (
            <div
              className="col-12 col-md-6 col-lg-4"
              key={service.id}
              style={{ marginBottom: "54px" }}
            >
              <div className="article-wrapper d-flex flex-column h-100">
                <figure>
                  <img src={service.imageUrl} alt={service.title} />
                </figure>
                <div className="d-flex flex-column flex-grow-1">
                  <h5 style={{ color: "#d52e32" }} className="fw-bold">
                    {service.title}
                  </h5>
                  <p>{service.description}</p>
                </div>
                <div className="mt-2 d-flex">
                  <Link
                    key={service.id}
                    to={`/services/${service.slug}`}
                    state={service}
                    className="d-flex align-items-center gap-3 mt-auto mb-3"
                  >
                    Read More
                    <FaArrowRightLong />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <Pagination /> */}
      <section
        style={{ backgroundColor: "rgba(227, 227, 227, 1)" }}
        className="mb-30"
      >
        <CallToActionSection />
      </section>
    </div>
  );
};

export default ServiceOverview;
