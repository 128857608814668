import React, { useState, useEffect, useRef } from "react";
import { startCountAnimation } from "../../../utils/CountAnimation/CountAnimation";
import useOnScreen from "../../../Hooks/useOnScreen";
import "./Regular.css";

const amenitiesData = [
  {
    amount: 170,
    description: "Owned from Properties transactions",
    iconClass: "bi bi-house",
  },
  {
    amount: 20000,
    description: "Properties for Buy & sell Successfully",
    iconClass: "bi bi-shop",
  },
  {
    amount: 400,
    description: "Daily completed transactions",
    iconClass: "bi bi-calendar-check",
  },
  {
    amount: 200,
    description: "Regular Clients",
    iconClass: "bi bi-person-check",
  },
];

const Regular = () => {
  const [counts, setCounts] = useState(amenitiesData?.map(() => 0));
  const sectionRef = useRef(null);

  // Use the custom hook to track visibility
  const isVisible = useOnScreen(sectionRef);

  useEffect(() => {
    if (isVisible) {
      // Reset counts to 0 before starting the animation
      setCounts(amenitiesData?.map(() => 0));
      startCountAnimation(amenitiesData, setCounts);
    }
  }, [isVisible]); // Trigger when the section becomes visible

  return (
    <div
      className="hero-section  border d-flex align-items-center p-5"
      ref={sectionRef}
    >
      <div className="container ">
        <div
          className="row "
          data-aos="fade-up"
          data-aos-duration={1200}
          data-aos-delay={100}
        >
          {/* <div className="d-flex "> */}
          {amenitiesData?.map((item, index) => (
            <div key={index} className="col-md-3 col-6 mb-3">
              <div className="amenities__icone text-center">
                <span>
                  <i className={item.iconClass}></i>
                </span>
              </div>
              <h2 className="text-white text-center">{counts[index]}+</h2>
              <h6 className="text-white text-center">{item.description}</h6>
            </div>
          ))}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Regular;
