import React from "react";
import Dynamic from "../../Components/DynamicBanner/Dynamic";
import "./TechnicalService.css";
import JoinAsRealEstateAgent from "../HeroSection/JoinAsRealEstateAgent/JoinAsRealEstateAgent";
import tcserviceBG from "../../assets/advisory_bg.jpg";
import techService_img1 from "../../assets/analog-landscape-city-with-buildings 1.jpg";
const features = [
  {
    id: 1,
    title: "Infrastructure Repair & Maintenance",
    description:
      "Efficient and reliable solutions for all your infrastructure upkeep needs.",
    icon: "bi-tools",
  },
  {
    id: 2,
    title: "Exterior Fabric Management",
    description:
      "Expert services for preserving and maintaining the exterior fabrics as well as enhancing the long-lasting appeal.",

    icon: "bi-basket",
  },
  {
    id: 3,
    title: "Lift and Escalator Maintenance",
    description:
      "Providing smooth, safe, and uninterrupted movement of lifts and escalators.",
    icon: "bi-shield-lock",
  },
  {
    id: 4,
    title: "Security System Repairs, Maintenance",
    description:
      "Advanced maintenance and repair solution for the high-tech systems of security and surveillance.",
    icon: "bi-lock",
  },
  {
    id: 5,
    title: "HVAC and Air Conditioning Maintenance",
    description:
      "Best-optimized HVAC maintenance for high quality and energy efficiency of air.",
    icon: "bi-tools",
  },
  {
    id: 6,
    title: "Energy and Utilities Management",
    description:
      "Smart strategies for cost-effective and sustainable energy and utilities management.",
    icon: "bi-lightning",
  },
  {
    id: 7,
    title: "Asset Management Services",
    description:
      "Increasing asset capacity for better returns in the long-term value.",
    icon: "bi-pie-chart",
  },
  {
    id: 8,
    title: "Handyman Services",
    description:
      "Professional, on-call handyman services for property maintenance are made simple.",
    icon: "bi-tools",
  },
];

const TechnicalService = () => {
  return (
    <>
      <Dynamic
        backgroundImage={tcserviceBG}
        heading="Technical Services"
        subheading="Learn more about our company and values."
      />

      <div
        className="ltn__about-us-area"
        data-aos="fade-up"
        data-aos-duration={1200}
        data-aos-delay={20}
      >
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-md-5 col-lg-5 d-flex justify-content-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left ">
                <img src={techService_img1} alt="about-us-img" />
              </div>
            </div>
            <div className="col-md-7 col-lg-6 pt-4">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h2 className="text-center text-sm-start">
                    Unlock New Possibilities with Advanced Technical Services
                  </h2>

                  <p className="lh-lg">
                    Implement the latest tools and strategies to elevate your
                    real estate business. Our expert technical services help
                    improve asset performance, enhance value, and create
                    long-term success. Don’t just maintain, innovate, and expand
                    your reach beyond existing limits.
                  </p>
                  {/* <p>
                    Take your business beyond its existing limits. Implement
                    tools, processes, and strategies that improve the value and
                    performance of your real estate. Take your business beyond
                    its existing limits. Implement tools, processes, and
                    strategies that improve the value and performance of your
                    real estate.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-sm container-md container-xl container-lg container-xxl">
        <div className="row">
          <div className="col-lg-12 pb-3">
            <h2 className="section-title text-center">Our Core Services</h2>
          </div>
        </div>
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-duration={1200}
          data-aos-delay={20}
        >
          {features?.map((feature) => (
            <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={feature.id}>
              <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1">
                <div className="ltn__feature-icon">
                  <span>
                    <i className={`bi ${feature.icon} icon-red`}></i>
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>{feature.title}</h4>
                  <p className="pt-2 mb-5">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <JoinAsRealEstateAgent />
    </>
  );
};

export default TechnicalService;
