import React from "react";
import "./Apptoach.css";
import "../../ContactSection/ContactUs.css";
import Approach from "../../../assets/homepage.png";

const Apptoach = () => {
  return (
    <>
      <section className="about__section about__page--section section--padding">
        <div className="container">
          <div
            className="row pt-4"
            data-aos="fade-up"
            data-aos-duration={1200}
            data-aos-delay={100}
          >
            <div className="col-md-12 pt-lg-5 col-lg-12 col-xl-7 mb-5 mt-3">
              <h2 style={{ color: "#D52E32" }}>Our Approach</h2>
              <p>
                Our commitment to simplicity and efficiency sits at the heart of
                our property management services. The complexities will be made
                simple as we carry out proven strategies, streamlined processes,
                and expert solutions. Be it developers, investors, or owners;
                our intention is to ensure hassle-free management on a
                day-to-day basis, thus increasing your returns.
              </p>
              <p>
                Our detailed SOPs and committed people address operational
                problems head-on with customized solutions to the benefit of
                property value, tenant experience, and long-term profitability.
                The decisions we make are based on analytical insights,
                guaranteeing that your properties are set on a path toward
                successful, sustainable performance.
              </p>
              <p>
                We are a team of passionate individuals who prioritize
                integrity, transparency towards clients, and excellent service
                delivery. We help clients thereafter from our expertise to
                realize their new opportunities in developing quality living and
                working spaces.
              </p>
              <p className="fs-6 fw-semibold">
                Let us handle the complexities so you can enjoy the rewards of
                smart property management.
              </p>

              {/* 
              <Link
                to="/about"
                className="p-2 px-5 rounded-pill text-white"
                style={{ backgroundColor: "#fa4a4a" }}
              >
                Know More
              </Link> */}
            </div>
            <div className="col-md-12 col-lg-12 col-xl-5 mt-0 mt-lg-5">
              <div>
                <img
                  className="aprroaches mt-0 mt-lg-5"
                  src={Approach}
                  alt="Approach_img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Apptoach;
