import React from "react";
import "./CallToActionSection.css";
import { Link } from "react-router-dom";
const CallToActionSection = () => {
  return (
    <div>
      <section
        className="call__action--section section--padding"
        data-aos="fade-up"
        data-aos-duration={1200}
        data-aos-delay={100}
      >
        <div className="container py-3">
          <div className="call__action--container mt-3 mb-3">
            <div className="call__action--inner ">
              <div className="row ">
                <div className="col-md-8">
                  <h2 className=" text-white fw-semibold call__action--title">
                    Leave Us Your Property Management Woes!
                  </h2>
                  <p className="text-white">
                    The management of properties cannot be overly stressful.
                    With WPM India, you get expert solutions tailored to your
                    needs. Get in touch today, and let us elevate your property
                    management WPM India to the next level!
                  </p>
                </div>
                <div className="col-md-4 d-flex justify-content-md-end justify-content-center">
                  <div className="call__action--right d-flex align-items-center text-black">
                    <Link
                      className="p-2 px-5 rounded-pill text-black"
                      style={{ backgroundColor: "white" }}
                      to="/contact-us"
                    >
                      Click Here
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CallToActionSection;
