import React from "react";
import DynamicBanner from "../../Components/DynamicBanner/Dynamic";
import LocationMap from "./Map";
import OfficeAddress from "./OfficeAddress";
import "bootstrap/dist/css/bootstrap.min.css";
import contactBnaeer from "../../assets/advisory_bg.jpg";

const ContactUs = () => {
  return (
    <>
      <DynamicBanner
        backgroundImage={contactBnaeer}
        heading="Contact Us"
        subheading="Connect with us and bring your dreams to life!"
      />

      <section className="contact__section py-5">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-9 col-lg-7">
              <div className="mb-4 text-center">
                <h2 className="fw-bold">Get in Touch with Us</h2>
              </div>
              <div className="card p-4 shadow-sm rounded-3">
                <form>
                  <div className="row">
                    {[
                      {
                        placeholder: "Full Name",
                        type: "text",
                        icon: "bi-person-fill",
                      },
                      {
                        placeholder: "Enter Email Address*",
                        type: "email",
                        icon: "bi-envelope-fill",
                      },
                      {
                        placeholder: "Company Name",
                        type: "text",
                        icon: "bi-building",
                      },
                      {
                        placeholder: "Business Phone Number",
                        type: "text",
                        icon: "bi-telephone-fill",
                      },
                      {
                        placeholder: "How Can I Help You",
                        type: "text",
                        icon: "bi-question-circle-fill",
                      },
                      {
                        placeholder: "Management Level",
                        type: "text",
                        icon: "bi-briefcase-fill",
                      },
                      {
                        placeholder: "City",
                        type: "text",
                        icon: "bi-geo-alt-fill",
                      },
                      {
                        placeholder: "Country",
                        type: "text",
                        icon: "bi-globe",
                      },
                    ].map((input, idx) => (
                      <div className="col-md-6 mb-3" key={idx}>
                        <div className="input-group">
                          <span className="input-group-text">
                            <i className={`bi ${input.icon}`}></i>
                          </span>
                          <input
                            type={input.type}
                            className="form-control"
                            placeholder={input.placeholder}
                          />
                        </div>
                      </div>
                    ))}

                    <div className="col-12 mb-3">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Enter Your Message here"
                          style={{ height: "100px" }}
                        ></textarea>
                        <label>Enter Your Message here</label>
                      </div>
                    </div>
                  </div>
                  <button className="btn btn-danger w-100 rounded-pill">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-3 col-lg-5 mt-4 mt-lg-0">
              <LocationMap />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
