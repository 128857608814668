import React from "react";
import "./Service.css";
import { Link, useNavigate } from "react-router-dom";

const cardData = [
  {
    id: 1,
    title: "Facility Management",
    slug: "licensing-compliance",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-house-gear", // Represents facility and property management
  },
  {
    id: 2,
    title: "Business Support Solutions",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-tools", // Represents business tools and support
  },
  {
    id: 3,
    title: "Asset Management",
    slug: "transaction-advisory-solution",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-box-seam", // Represents managing assets and inventory
  },
  {
    id: 4,
    title: "Manpower Solutions",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-people", // Represents workforce and human resources
  },
  {
    id: 5,
    title: "Ambience Management",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-brightness-high", // Represents lighting, environment, and ambience
  },
  {
    id: 6,
    title: "Licensing Compliance",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-file-earmark-check", // Represents legal compliance and documentation
  },
  {
    id: 7,
    title: "Transaction Advisory Solutions",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-currency-exchange", // Represents financial transactions and advisory
  },
  {
    id: 8,
    title: "Infrastructure Management",
    backgroundColor: "#FFFFFF",
    iconClass: "bi bi-buildings", // Represents infrastructure and large-scale property management
  },
  // {
  //   id: 9,
  //   title: "Tenant Management Leasing",
  //   backgroundColor: "#FFFFFF",
  //   iconClass: "bi bi-person-badge", // Represents leasing and tenant management
  // },
  // {
  //   id: 10,
  //   title: "Sale and Purchase",
  //   backgroundColor: "#FFFFFF",
  //   iconClass: "bi bi-cart-check", // Represents buying and selling
  // },
];

//   {
//     id: 1,
//     slug: "licensing-compliance",
//     title: "Licensing Compliance",
//     description:
//       "At WPM India, our Licensing Compliance service ensures that all your property management practices adhere to a comprehensive set of rules and guidelines within the real estate industry. Our team is proficient in navigating.",
//     imageUrl: service1,
//   },
//   {
//     id: 2,
//     slug: "transaction-advisory-solution",
//     title: "Transaction Advisory Solution",
//     description:
//       "Our Transaction Advisory Solution assists clients in navigating the complexities of property transactions, including buying, selling, leasing, subletting, and consolidating. We help verify the condition and value of assets at the best market price.",
//     imageUrl: service2,
//   },
//   {
//     id: 3,
//     slug: "facility-management",
//     title: "Facility Management",
//     description:
//       "Our Facility Management service covers various disciplines, including housekeeping, operations, property management, maintenance, and physical asset security. We integrate people, processes, and technology to deliver comprehensive management solutions.",
//     imageUrl: service1,
//   },
//   {
//     id: 4,
//     slug: "parking-facility-management",
//     title: "Parking Facility Management",
//     description:
//       "Our Parking Facility Management service involves planning, designing, operating, and maintaining parking facilities. We manage these facilities on behalf of property owners or other entities, ensuring efficient and effective operations.",
//     imageUrl: service2,
//   },
//   {
//     id: 5,
//     slug: "ambiance-management",
//     title: "Ambiance Management",
//     description:
//       "In our Ambiance Management service, we ensure the protection of your assets and people while maintaining a welcoming appearance. We offer high-quality, cost-effective security services using the ambiance management approach.",
//     imageUrl: service1,
//   },
//   {
//     id: 6,
//     slug: "business-support-management",
//     title: "Business Support Management",
//     description:
//       "Our Business Support Management service aids in the smooth operation of businesses by handling various functions such as financial transactions, budgeting, and financial reporting. We also provide technology solutions and system maintenance.",
//     imageUrl: service2,
//   },
// ];
const Service = () => {
  const navigate = useNavigate();
  return (
    <div className="service-section pt-4 mb-4">
      <div className="container">
        <div className="row g-3 p-3">
          {/* Left Side - Expertise Section */}
          <div className="col-md-4 col-lg-3 pt-4">
            <div className="expertise-card p-4 rounded text-white text-center">
              <h3 className="text-white">Our Expertise</h3>
              <h6 className="text-white lh-lg text-justify">
                We don’t just solve problems, we future-proof your property
                journey. <strong>Our Core Focus</strong> is Alleviating
                operational headaches for developers and investors with
                actionable strategies, not just advice.
              </h6>

              <button
                type="button"
                className="bg-danger w-100 text-white text-decoration-none p-2"
                onClick={() => navigate("/service")}
              >
                Know More
              </button>
            </div>
          </div>

          {/* Right Side - Services Grid */}
          <div className="col-md-8 col-lg-9 pt-3">
            <div className="row g-3 mb-4">
              {cardData?.map((item, index) => (
                <div key={index} className="col-md-4 col-xl-3 col-6">
                  <div
                    className="service-card d-flex flex-column align-items-center p-4 rounded h-100 rounded-4"
                    style={{ backgroundColor: item.backgroundColor }}
                  >
                    <i className={`${item.iconClass} fs-1`}></i>
                    <Link
                      to="/service"
                      className="text-black text-center mt-2 fw-semibold cursor-pointer"
                    >
                      {item.title}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
